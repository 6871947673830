import './Home.css';
import './Header.css';
import './Footer.css';
import './Fonts/style.css'
import './SlideShow.css';
import './SlideShow.js';
import phone from './Images/phone.png';
import logo from './Images/Primair-logo-Paros.png';
import olijf from './Images/olijven-groen.png';
import paros3 from './Images/Grieks-Restaurant-Paros-Terras3.jpg'
import parosb from './Images/Grieks-Restaurant-Paros-buiten.jpg'
import React, {useEffect, useState } from "react";
import { Helmet } from 'react-helmet';


const Home = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        // Set a timer to hide the element after 5 seconds
        const timer = setTimeout(() => {
            setVisible(false);
        }, 0);

        // Cleanup the timer if the component unmounts before the timer finishes
        return () => clearTimeout(timer);
    }, []);
    return (
        <div className="App">
            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="robots" content="index, follow"/>
                <meta name="description" content="wat onze gasten van paros vinden. Typisch Grieks restaurant met bijzonder vers en perfect bereide schotels. Ruim voldoende op je bord. Het gegrilde vlees ..."/>
                <title>Grieks Restaurant Paros | Het restaurant waar je écht even kunt relaxen.</title>
                <link rel="icon" href="./Images/Primair_logo_Paros.ico" type="image/x-icon"/>
                <link rel="apple-touch-icon" sizes="180x180" href="Images/Primair-logo-Paros.png"/>
                <link rel="shortlink" href="https://restaurant-paros-echt.nl/"/>
                <link rel="canonical" href="https://restaurant-paros-echt.nl/"/>
                <meta name="revisit-after" content="7 days"/>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            </Helmet>
            <header>
                <div className="top-header">
                    <div className="width-div">
                        <p>
                            <img alt="Grieks Restaurant Paros" src={phone} width="12px"/>
                            <span className="top-header-span">
                                Reserveer je plek via
                                <a href="tel:0475 487 814"> 0475 487 814</a>
                            </span>
                        </p>
                    </div>
                </div>
                <div className="main-header">
                    <nav>
                        <ul className="nav">
                            <li>
                                <a aria-current="page" href="./">home</a>
                            </li>
                            <li>
                                <a href="./OverParos">over paros</a>
                            </li>
                            <li>
                                <a href="./">
                                    <img width="60px" alt="Grieks Restaurant Paros" id="logo"
                                         src={logo}/>
                                </a>
                            </li>
                            <li>
                                <a href="./Menu">menu</a>
                            </li>
                            <li>
                                <a href="./Contact">contact</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <div className="main">
                <div className="snowfall" id="snowfall"></div>
                {visible && (
                    <div className="closed">
                        <p>
                            Beste bezoeker,
                            <br></br>
                            <br></br>
                            Het is tijd voor een beetje zon, zee, en ontspanning! Grieks restaurant Paros gaat op vakantie
                            vanaf 8 juli. Maar geen zorgen, we zijn terug op 24 juli, helemaal opgeladen en klaar om jullie
                            weer te verwelkomen met onze heerlijkste Griekse
                            gerechten.
                            <br></br>
                            <br></br>
                            Geniet van jullie zomer en we zien jullie graag snel weer!
                            <br></br>
                            <br></br>
                            Zonnige groetjes,
                            <br></br>
                            Team Paros
                        </p>
                    </div>
                )}

                <div className="top-space"></div>
                <div>
                    <h1>grieks restaurant paros:</h1>
                    <h6>het restaurant waar je écht even kunt relaxen.</h6>
                    <img alt="Grieks Restaurant Paros" className="h1-image" src={olijf}/>
                </div>

                <div className="space"></div>
                <div className="content-box">
                    <div className="content-img">
                        <img decoding="async" loading="lazy"
                             src={paros3}
                             alt="Grieks Restaurant Paros"
                             height="auto" width="100%"></img>
                    </div>
                    <div className="content-text">
                        <p className="m-b-15"><strong>Grieks
                            restaurant Paros is hét restaurant waar je even heerlijk tot rust
                            kan komen.</strong> Heb je een lange dag achter de rug en wil je even achteroverleunen
                            terwijl je geniet van onze Griekse specialiteiten? En wil je er even onbezorgd op
                            uit? <strong>Wees welkom!</strong></p>
                        <p>Het <strong>sfeervolle</strong> en <strong>relaxte
                            gevoel</strong> overdragen aan onze gasten na een drukke dag;
                            dat is waar wij naar streven. Je hoeft even helemaal niets. Af
                            en toe is het namelijk heerlijk om niets te moeten en te
                            ontspannen terwijl je het koken van je maaltijd overlaat aan de
                            chef. Bij restaurant Paros kun je tot rust komen en ondertussen
                            genieten van heerlijke Griekse gerechten en je favoriete
                            drankje.</p>
                        <a className="button" href="./OverParos">meer over
                            restaurant paros</a>
                    </div>
                </div>

                <div className="space"></div>
                <div className="specials">
                    <h3>De specialiteiten van {'\u00A0'}Paros</h3>
                    <p className="specials-text-title">BEKRI MEZE</p>
                    <p className="specials-text">Stukjes varkenshaas met metaxa saus uit de pan</p>
                    <p className="specials-text-title">KARDITSA SCHOTEL</p>
                    <p className="specials-text">Kipfilet met panseta in een romige mastiksaus met ananas en kardamon
                        uit de pan</p>
                    <p className="specials-text-title">LEFKES SCHOTEL</p>
                    <p className="specials-text">6 gegrilde lamskoteletten</p>
                    <p className="specials-text-title">OLYMPUS SCHOTEL</p>
                    <p className="specials-text">Een combinatie met Iberico varkenskotelet, lamskoteletten en gyros</p>
                    <p className="specials-text-title">RIBEYE</p>
                    <p className="specials-text">300 g. mals gegrilde rundvlees met gyros</p>
                    <p className="specials-text-title">FILETO PEPPER</p>
                    <p className="specials-text">Biefstuk van de haas met pepersaus</p>
                    <p className="specials-text-title">FILETO MANITARIA</p>
                    <p className="specials-text">Biefstuk van de haas met champignonsaus</p>
                    <a className="button2" href="./Menu">Bekijk het hele menu</a>
                </div>
                <div className="space"></div>
            </div>
            <div className="slideshow">
                <div className="slideshow-container">

                    <div className="mySlides fade">
                        <h4>wat onze gasten van paros
                            vinden</h4>
                        <p className="slideshow-text">Typisch Grieks restaurant
                            met bijzonder vers en perfect bereide schotels. Ruim
                            voldoende op je bord. Het gegrilde vlees behoort tot de
                            beste dat ik ooit gegeten heb. Bediening is vlot en
                            vriendelijk. We kregen ondanks dat we niet gereserveerd
                            hadden toch een tafeltje en werden netjes geholpen. Ga
                            ik zeker nog een keer heen en kan ik iedereen
                            aanbevelen.</p>
                        <p className="slideshow-text">– Rob Kollmann</p>
                    </div>

                    <div className="mySlides fade">
                        <h4>wat onze gasten van paros
                            vinden</h4>
                        <p className="slideshow-text">Gezellig en goed eten.
                            Vriendelijk personeel. Buiten zitten wanneer de
                            druivenstruiken voor schaduw zorgen is echt geweldig.
                            Mezes van de menu-kaart is aan te raden.</p>
                        <p className="slideshow-text">– John van den Bungelaar</p>
                    </div>

                    <div className="mySlides fade">
                        <h4>wat onze gasten van paros
                            vinden</h4>
                        <p className="slideshow-text">Vriendelijke bediening en
                            super lekker eten. Heerlijk mals vlees gebakken op de
                            gril wat lekker sappig is. Salade is smaakvol vers en
                            alles wordt heerlijk gekruid.<br/>
                                Onze favoriet!</p>
                        <p className="slideshow-text" className="slideshow-text">– Stephan Hoffman</p>
                    </div>

                    <div className="mySlides fade">
                        <h4>wat onze gasten van paros
                            vinden</h4>
                        <p className="slideshow-text">Vriendelijk en geduldig
                            personeel, alles was heerlijk gaar en op smaak. Dit was
                            mijn eerste keer bij Paros, maar zij staan nu wel
                            degelijk in mijn top 10 bovenaan, voor eetgelegenheden.</p>
                        <p className="slideshow-text">– Wim Melching</p>
                    </div>

                    <div className="mySlides fade">
                        <h4>wat onze gasten van paros
                            vinden</h4>
                        <p className="slideshow-text">Restaurant Paros is een
                            gezellig en mooie ingerichte zaak, met een groot terras.
                            De bediening is vlot en heel vriendelijk en spontaan.
                            Het eten is ook heel lekker!</p>
                        <p className="slideshow-text">– Karin M</p>
                    </div>


                </div>
                <br/>
                    <div className="t-center">
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                        <span className="dot"></span>
                    </div>
            </div>
            <div className="main">
                <div className="space"></div>
                <div className="content-box">
                    <div className="content-text">
                        <p className="m-b-15">Begin je al langzaam weg te dromen? Dat is mooi, maar langskomen
                            is natuurlijk nog veel leuker! Voor een ontspannen avond in ons Griekse restaurant reserveer
                            je <u>hier</u>.</p>
                        <h3 className="h3-home">Thuis genieten van de Griekse
                            keuken?</h3>
                        <p>Ook dit is mogelijk! Bestel bij restaurant Paros jouw favoriete
                            gerechten om af te halen.</p>
                        <a className="button" href="./Menu">bestel jouw
                            afhaalmenu</a>
                    </div>
                    <div className="content-img p-l-50">
                        <img decoding="async" loading="lazy"
                             src={parosb}
                             alt="Grieks Restaurant Paros"
                             height="auto" width="100%"/>
                    </div>
                </div>
                <div className="space"></div>
            </div>
            <footer>
                <div className="main">
                    <div className="main-footer">
                        <div className="main-f-c">
                            <h4 className="footer-title">OPENINGSTIJDEN</h4>
                            <p>
                                Maandag: gesloten<br/>
                                Dinsdag: gesloten<br/>
                                Woensdag: 17:00 - 22:00<br/>
                                Donderdag: 17:00 - 22:00<br/>
                                Vrijdag: 17:00 - 22:00<br/>
                                Zaterdag: 17:00 - 22:00<br/>
                                Zondag: 15:00 - 22:00<br/>
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">CONTACT</h4>
                            <p>
                                TEL: 0475 487 814
                            </p>
                        </div>
                        <div className="main-f-c">
                            <h4 className="footer-title">BEREIKBAARHEID</h4>
                            <p>
                                Grieks restaurant Paros is gemakkelijk bereikbaar met de auto, fiets of het openbaar
                                vervoer. Er is genoeg parkeergelegenheid op het eigen parkeerterrein achter het
                                restaurant. Daarnaast is het mogelijk om te parkeren bij de openbare parkeerplaats van
                                de Albert Heijn of de Lidl.
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="bottom-footer"></div>
        </div>
    );
}

export default Home;
